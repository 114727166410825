import React, { useState, useRef } from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import GoogleMapReact from 'google-map-react'

import { Layout, Block, EnquireForm } from '../components'

import { container, padding, button, bgIcon, pageTitle, menuPadding } from '../styles/global'
import { slugify } from '../utils'
import { getFunctionsNav } from '../utils/functions'
import { GOOGLE_MAPS_KEY } from '../constants'
import { mapTheme } from '../data/map'
import { absoluteCover, media } from '../styles/utils'
import { navigate } from '@reach/router'

const FunctionRooms = ({ pageContext, ...props }) => {
  const enquireForm = useRef(null)
  const data = JSON.parse(pageContext.acf_json)

  const goToForm = () => {
    console.log(enquireForm.current)
    window.scrollTo(0, enquireForm.current.offsetTop)
  }

  const renderLocation = () => {
    const { map, location } = data
    if (!location || !map) return

    const mapProps = {
      defaultZoom: 17,
      defaultCenter: {
        lat: parseFloat(map.lat),
        lng: parseFloat(map.lng),
      },
      bootstrapURLKeys: {
        key: GOOGLE_MAPS_KEY,
      },
      options: {
        styles: mapTheme,
      },
    }

    const markerProps = {
      lat: parseFloat(map.lat),
      lng: parseFloat(map.lng),
      text: 'hello',
    }

    return (
      <Location id={'location'}>
        <Heading>Location</Heading>
        <MapWrapper>
          <GoogleMapReact {...mapProps}>
            <Marker {...markerProps} />
          </GoogleMapReact>
        </MapWrapper>

        <Text dangerouslySetInnerHTML={{ __html: location }} />
      </Location>
    )
  }

  const renderSlider = images => {
    if (!images) return

    return <Block layout={'slider'} images={images} hoverArrows />
  }

  const renderBlocks = blocks => {
    if (!blocks) return

    return blocks.map((block, i) => {
      return <Block layout={block.acf_fc_layout} key={i} {...block} />
    })
  }

  const renderSections = () => {
    if (!data.room_modules) return

    return data.room_modules.map((item, i) => {
      // console.log(item)

      return (
        <Section key={i} id={slugify(item.title)}>
          <Heading>{item.title}</Heading>
          {renderSlider(item.image_slider)}

          <Info>
            <Blocks>{renderBlocks(item.blocks)}</Blocks>

            <ButtonWrapper>
              {item.display_enquire_now_button && (
                <Button href="https://pepesitalianandliquor.tripleseat.com/party_request/23084">
                  Enquire Now
                </Button>
              )}
            </ButtonWrapper>
          </Info>
        </Section>
      )
    })
  }

  return (
    <Layout meta={data && data.seo} menu={pageContext.menu}>
      <TitleContainer>
        <BackgroundImage
          style={{
            backgroundImage:
              'url(https://admin.pepesitalian.com.au/wp-content/uploads/2020/03/PEP02-1600x1066.jpg)',
          }}
        />
        <Title>Functions</Title>

        {/* Menu here */}

        <Menu>
          {data.room_modules &&
            data.room_modules.map(room => (
              <MenuItem>
                <Link to={`#${slugify(room.title)}`}>
                  <Text>{room.title}</Text>
                </Link>
              </MenuItem>
            ))}
        </Menu>
      </TitleContainer>

      <Container color="red">{renderSections()}</Container>

      <Container>{renderLocation()}</Container>
    </Layout>
  )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Button = styled.a`
  ${button}
`

// Layout

const Container = styled.div`
  ${container}
  ${padding}
  ${menuPadding}
  
	flex-direction: column;

  > ${Heading} {
    ${pageTitle}
  }
`

const BackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
`

const Title = styled.div`
  font-family: 'learning-curve';
  font-size: 250px;
  color: #ffffff;
  font-weight: bold;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  user-select: none;

  ${media.tablet`
		font-size: 23vw;
	`}

  ${media.phone`
		top: 15%;
\	`}
`

// Sections

const Menu = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  background: #524d4d57;
  padding: 0;
  margin: 0;
  border-radius: 5px;

  ${media.phone`
	flex-wrap: wrap;
	bottom: 10%;
	`}
`

const MenuItem = styled.div`
  padding: 10px 15px;
  color: white;
`

const Blocks = styled.div``
const ButtonWrapper = styled.div``

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Heading} {
    ${pageTitle}
    margin-top: 0px;
  }

  ${Info} {
    display: flex;
    width: 100%;
    max-width: 940px;
    margin-top: 45px;

    ${Blocks} {
      flex: 1;
      padding-right: 100px;
    }

    ${ButtonWrapper} {
      flex: 0 1 300px;

      ${Button} {
        flex: 1;
      }
    }

    ${media.tablet`
			${Blocks} {
				padding-right: 32px;
			}
		`}

    ${media.phone`
			margin-top: 16px;
			flex-direction: column;

			${Blocks} {
				padding-right: 0;
			}

			${ButtonWrapper} {
				margin-top: 24px;
				flex: 0 1 auto;

				display: flex;
				justify-content: center;
			}
		`}
  }

  ${media.tablet`
		${Heading} {
			margin: 30px 0 0 0;
		}
	`}

  ${media.phone`
		${Heading} {
			margin: 40px 0 0 0;
		}
	`}
`

// Form

const FormWrapper = styled.div``

// Location

const Text = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: white;

  sup {
    font-size: 14px;
    line-height: 0;
    transform: translateY(3px);
    display: inline-block;
  }

  ${media.phone`
		p, a {
			font-size: 16px;
			line-height: 20px;
		}

		sup {
			font-size: 12px;
			transform: translateY(4px);
		}
	`}
`

const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 128px;

  ${Heading} {
    ${pageTitle}
    margin: 48px 0 40px;
  }

  ${Text} {
    margin-top: 52px;
  }

  ${media.phone`
		padding-top: 48px;

		${Text} {
			margin-top: 40px;
		}
	`}
`

const MapWrapper = styled.div`
  height: 500px;
  width: 100%;
  max-width: 940px;

  ${media.phone`
		margin-top: 32px;
		height: 220px;
	`}
`

const Marker = styled.div`
  background-image: url(${require('../assets/icons/map-marker.svg')});
  width: 19px;
  height: 33px;
  ${bgIcon}
`

// Layout

const TitleContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;

  /* Slider Overrides */

  .slider-arrow {
    background-image: url(${require('../assets/icons/slider-arrow-white.svg')});
    color: white !important;
  }

  .hover-section {
    width: 180px;
  }
`

const sliderStyles = css`
  width: 100vw;
  height: calc(100vh - 50px);
  background: black;

  .slider-image {
    opacity: 0.85;
  }

  ${media.tablet`
		height: 100vh;
	`}
`

// Logo

const Logo = styled.div`
  ${bgIcon}
  ${absoluteCover}
	left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: calc(100vw - 84px);

  ${props => {
    if (props.slug == 'pepes')
      return css`
        background-image: url(${require('../assets/images/pepes-logo.svg')});
        max-height: 422px;
        max-width: 524px;

        ${media.phone`
				&::before {
					content: '${props => props.tagline}';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					text-align: center;

					font-weight: bold;
					color: white;
					margin-top: auto;
					text-transform: uppercase;
					font-size: 16px;
				}
			`}
      `

    if (props.slug == 'trunk-diner')
      return css`
        background-image: url(${require('../assets/images/trunk-diner-logo.svg')});
        max-width: 480px;
        max-height: 242px;
      `
  }}
`

export default FunctionRooms
